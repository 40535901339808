<template>
  <div class="covid">
    <div class="covid-wrapper">
      <div class="covid-content">
        <div class="covid-block">
          <div class="covid-paragraph">
            <p class="title">COVID-19 Regulations</p>
            <p class="text">
              Since the pandemic was declared in early February 2010, we have
              received more than one million calls and online requests for help.
              In particular, calls to our hardship line have increased by 800
              per cent compared to the period before the pandemic, therefore we
              are moving our main call-centre to the United Kingdom where our
              Senior managers are based, this results in a 24-hour online
              assistance for our clients.
            </p>
            <p class="text">
              Over the same period, visits by customers to bank branches have
              fallen more than 50 per cent in some branches as people follow
              social distancing and lockdown requirements. Transaction volumes
              in branch have dropped significantly as a result while there has
              been a large increase in customers doing their banking through out
              online banking platform.
            </p>
            <p class="text">
              As part of the support we are providing to customers affected by
              the coronavirus pandemic, we are moving our branches to an online
              basis with an opportunity for a remote corporate and personal
              account opening.
            </p>
            <p class="text-bold">
              We hope to return back to our normal schedule in 2021!
            </p>
          </div>
        </div>
        <div class="covid-block">
          <div class="covid-paragraph">
            <img src="../../assets/home/imgCovid.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.covid-wrapper {
  width: 1170px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.covid-content {
  display: flex;
}
.covid-block {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
  padding-bottom: 30px;
}
.text {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
  padding-bottom: 20px;
}
.text-bold {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  width: 83%;
  color: #000000;
  margin: 0 auto;
}
</style>
