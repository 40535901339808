<template>
  <div class="account-wrapper">
    <div class="account-container margin-bottom-120">
      <div class="header-wrapper">
        <div class="account__block">
          <div class="account__text d-flex-rn flex-center">
            <h1>FAST. INNOVATIVE. WELL-ROUNDED.</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="header">
      <div
        @click="$router.push('/personal-account/details')"
        :class="[
          'header-item',
          { 'header-item__active': $route.name === 'details' },
        ]"
      >Account Details</div>
      <div
        @click="$router.push('/personal-account/transactions')"
        :class="[
          'header-item',
          { 'header-item__active': $route.name === 'transactions' },
        ]"
      >Statement</div>
      <div
        @click="$router.push('/personal-account/international-transfer')"
        :class="[
          'header-item',
          { 'header-item__active': $route.name === 'internationalTransfer' },
        ]"
      >SWIFT/SEPA Transfer</div>
      <div
        @click="$router.push('/personal-account/intra-transfer')"
        :class="[
          'header-item',
          { 'header-item__active': $route.name === 'intraTransfer' },
        ]"
      >Internal Transfer</div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  async created() {
    this.checkToken()

    if (!this.$store.getters.user) {
      this.$store.commit('setUser', JSON.parse(sessionStorage.getItem('user')));
    }

    await this.$store.dispatch("getAccountTransaction")
      .then((data) => {
        if (data.code === 401) this.$router.push('/')
      })
  },

  beforeUpdate() {
    this.checkToken()
  },

  methods: {
    checkToken() {
      const token = sessionStorage.getItem('token')

      if (token) {
        const { exp } = JSON.parse(atob(token.split('.')[1]))

        if ((exp * 1000) < new Date().getTime()) this.$router.push('/')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.account-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 45px;
}
.account-container {
  // background: url("../../assets/account/bg-account.png");
  width: 100%;
  // height: 460px;
  background-position: center;
}
.account__text {
  h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-transform: uppercase;
  color: #ffffff;
  }

}
.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100%;
  max-width: 1920px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-bottom: 2px solid #385a41;

  @media print {
    display: none;
  }
  @media screen and (max-width: 1236px) {
    width: 100%;
  }
  @media screen and (max-width: 955px) {
    border: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96%;
  }
}

.header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  line-height: 32px;
  border-radius: 20px 20px 0px 0px;
  height: 41px;
  min-width: 241px;
  padding: 0 30px;

  &__active {
    color: #fff;
    background: #385a41;
  }
  @media screen and (max-width: 1236px) {
    min-width: auto;
  }
  @media screen and (max-width: 955px) {
    border-radius: 20px;
    margin-bottom: 30px;
    border: 0.5px solid #385a41;
    min-width: 241px;
  }
}
</style>
