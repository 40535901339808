<template>
  <div class="details-wrapper">
    <h3>Client Details</h3>
    <div class="user-wrapper">
      <div class="user">
        <span class="user__key">Client ID: </span>
        <span class="user__value">{{ user.client_id }}</span>
      </div>
      <div class="user">
        <span class="user__key">Client name: </span>
        <span class="user__value">{{ `${user.client_last_name} ${user.client_first_name}` }}</span>
      </div>
      <div class="user">
        <span class="user__key">Company Name: </span>
        <span class="user__value">{{ user.company_name }}</span>
      </div>
      <div class="user">
        <span class="user__key">Contact details: </span>
        <span class="user__value">{{ user.email }}</span>
      </div>
    </div>
    <!-- <h3>Password</h3> -->
    <div class="change-password">
      <h3>Change password</h3>
      <span v-if="!firstInpError" class="change-password__label text"
        >Current password</span
      >
      <span v-if="firstInpError" class="error-message">{{ errorMessage }}</span>
      <el-input
        placeholder="Enter your current password"
        v-model="currentPassword"
        class="change-password__input"
        type="password"
        @input="firstInpError = false"
      />
      <span v-if="!secondInpError" class="change-password__label text"
        >New password</span
      >
      <span v-if="secondInpError" class="error-message">{{
        errorMessage
      }}</span>
      <el-input
        placeholder="Enter your new password"
        v-model="newPassword"
        class="change-password__input"
        type="password"
        @input="
          secondInpError = false;
          notMatch = false;
        "
      />
      <span v-if="!thirdInpError" class="change-password__label text"
        >Confirm password</span
      >
      <span v-if="thirdInpError" class="error-message">{{ errorMessage }}</span>
      <el-input
        placeholder="Confirm your new password"
        v-model="repeatNewPassword"
        class="change-password__input"
        type="password"
        @input="
          thirdInpError = false;
          notMatch = false;
        "
      />
      <span v-if="notMatch" class="error-message">
        The new password and password confirmations do not match
      </span>
      <el-button @click="changePassword" type="primary" class="change-button">
        CHANGE
      </el-button>
    </div>
    <DetailsTable />
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import DetailsTable from "./DetailsTable.vue";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      loadingChangeBtn: true,
      errorMessage: "The password contains less than 8 characters",
      firstInpError: false,
      secondInpError: false,
      thirdInpError: false,
      notMatch: false,
    };
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem('user')))
    }
  },

  methods: {
    async changePassword() {
      const {
        firstInpError,
        secondInpError,
        thirdInpError,
        currentPassword,
        newPassword,
        repeatNewPassword,
      } = this;

      if (currentPassword.length < 8) this.firstInpError = true;
      if (newPassword.length < 8) this.firstInpError = true;
      if (repeatNewPassword.length < 8) this.firstInpError = true;
      if (firstInpError || secondInpError || thirdInpError) return;
      if (newPassword !== repeatNewPassword) {
        this.notMatch = true;
        return;
      }

      await this.$store.dispatch("changePassword", {
        oldPassword: currentPassword,
        newPassword,
      });
    },
  },

  computed: {
    ...mapGetters(['lang']),
    user() {
      return this.$store.getters.user
    },
  },

  components: {
    DetailsTable,
  },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  width: 100%;

}

.user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 842px;
  background: rgba(228, 229, 215, 0.85);
  padding: 25px 100px;
  margin-bottom: 20px;

  @media screen and (max-width: 955px) {
    width: 96%;
    padding: 25px 5px;
  }
}

.user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  padding-left: 10%;

  &__key {
    width: 40%;
    display: block;
    font-weight: 600;
    font-size: 15px;
    color: #000;
    text-align: left;
  }

  &__value {
    font-size: 16px;
    color: #000;
    text-align: left;
    width: 20%;

    @media screen and (max-width: 955px) {
      width: 50%;
    }
  }

  @media screen and (max-width: 955px) {
    padding: 0 10%;
    justify-content: space-between;
  }
}

.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 555px;
  background: rgba(228, 229, 215, 0.85);
  padding: 25px 25px;

  &__input {
    margin-bottom: 25px;
    width: 165px;
  }

  &__label {
    display: flex;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #000;
  }

  @media screen and (max-width: 955px) {
    width: 96%;
  }
}
.change-password__input {
  width: 395px;
}
.error-message {
  color: #cc3f55;
  font-size: 12px;
}

.change-button {
  margin-top: 25px;
}
.el-button {
  width: 150px;
  background: #385a41;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.el-table .cell {
  word-break: break-word !important;
}
</style>
