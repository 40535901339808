<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-top">
        <div class="logo-block">
          <img src="../assets/logo.png" alt="" />
          <div></div>
          <div></div>
        </div>

        <div class="footer-block">
          <ul class="footer-block">
            <li class="text-menu">Services</li>
            <li class="text-menu">Team</li>
            <li class="text-menu">About us</li>
            <li class="text-menu">Careers</li>
            <li class="text-menu">Contact us</li>
          </ul>
          <div>
            <span class="text-phone">+44 7507 900 176</span>
          </div>
        </div>
      </div>
      <div class="footer-bottom footer-block">
        <div class="footer-block">
          <p class="text-reserved">
            © 2021 Alexandria Bancorp Limited. All rights reserved.
          </p>
          <p class="text-menu-bottom">Privacy Statement | Terms of Use</p>
        </div>
        <div>
          <div class="form-block">
            <el-input placeholder="Email" v-model="email"></el-input>
            <el-button
              type="primary"
              @click="sendMessage"
              :loading="sendBtnLoading"
              :plain="true"
              >Get started</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      email: null,
    };
  },
  methods: {
    async sendMessage() {
      this.sendBtnLoading = true;
      await this.$store.dispatch("sendMessage", {
        "E-mail": this.email,
      });
      this.sendBtnLoading = false;
      this.$message({
        message: "Message sent successfully",
        type: "success",
      });
      this.email = null;
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  margin-top: 140px;
}
.footer-top {
  margin-bottom: 52px;
}
.footer-bottom {
  height: 135px;
  background: #313138;
}
.footer-block {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.logo-block {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.text-menu {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  cursor: pointer;
}
.text-phone {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  cursor: pointer;
}
.text-reserved {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  width: 42%;
}
.text-menu-bottom {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  width: 27%;
}
ul {
  li {
    padding-right: 45px;
  }
}
.form-block {
  display: flex;
}
.el-button--primary.is-plain {
  color: #fff;
  background: #2e4d4f;
  border-color: transparent;
}
</style>
