<template>
  <div class="history">
    <div class="history-wrapper">
      <div class="history-content">
        <div class="history-block">
          <div class="history-paragraph">
            <p class="title">Alexandria Bancorp Limited</p>
            <p class="text">
              Established in 1990, Alexandria Bancorp Limited offers
              personalized trustee, investment and private banking services to
              families around the globe.
            </p>
          </div>
          <div class="history-paragraph">
            <img src="../../assets/history/imgHistory.jpg" alt="" />
          </div>
        </div>
        <div class="history-block">
          <div class="history-paragraph">
            <p class="title">Alexandria Trust Corporation</p>
            <p class="text">
              Based in Barbados, Alexandria Trust Corporation specializes in
              trust and corporate administration services for private clients
              and multi-national businesses.
            </p>
          </div>
          <div class="history-paragraph">
            <p class="title">Alexandria Bancorp Limited Comoros</p>
            <p class="text">
              Established in 2020 in The Comoros Islands, a new branch that is
              providing non- CRS/FATCA regulated Tax-Free Accounts with the
              possibility to work with clients worldwide.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.history-wrapper {
  width: 1170px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.history-content {
  display: flex;
}
.history-block {
  display: flex;
  flex-direction: column;
}
.history-paragraph {
  height: 250px;
  width: 70%;
}
.title {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #987b2a;
  margin-bottom: 30px;
}
.text {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}
</style>
