<template>
  <div class="main-wrapper">
    <Main />
    <History />
    <Services />
    <About />
    <Covid />
    <Currency />
  </div>
</template>

<script>
import Main from "./main.vue";
import History from "./history.vue";
import Services from "./ourServices.vue";
import About from "./aboutUs.vue";
import Covid from "./covidRegulations.vue";
import Currency from "./currencyConverter.vue";

export default {
  components: {
    History,
    Main,
    Services,
    About,
    Covid,
    Currency,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}
</style>
