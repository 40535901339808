import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/home'
import OnlineBanking from '@/components/onlineBanking'

import PersonalAccount from '../components/PersonalAccount/index.vue'
import PersonalDetails from '../components/PersonalAccount/Details.vue'
import PersonalTransactions from '../components/PersonalAccount/Statement.vue'
import PersonalInternationalTransfer from '../components/PersonalAccount/InternationalTransfer.vue'
import PersonalIntraTransfer from '../components/PersonalAccount/IntraTransfer.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/OnlineBanking',
    name: 'OnlineBanking',
    component: OnlineBanking
  },
  {
    path: '/personal-account',
    name: 'personalAccount',
    component: PersonalAccount,
    children: [
      {
        path: 'details',
        name: 'details',
        component: PersonalDetails,
        meta: {title: 'Details'},
      },
      {
        path: 'transactions',
        name: 'transactions',
        component: PersonalTransactions,
        meta: {title: 'Transactions'},
      },
      {
        path: 'international-transfer',
        name: 'internationalTransfer',
        component: PersonalInternationalTransfer,
        meta: {title: 'International Transfer'},
      },
      {
        path: 'intra-transfer',
        name: 'intraTransfer',
        component: PersonalIntraTransfer,
        meta: {title: 'Intra Transfer'},
      },
    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
