<template>
  <div class="currency">
    <div class="currency-wrapper">
      <div class="currency-content">
        <div>
          <h1 class="title">Currency Converter</h1>
          <p class="subtitle">
            Rate valid as of 11:15 AM, February 15, 2021 Currency exchange rates
            with Alexandria
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.currency {
  background: #FAFAFA;
  padding-top: 100px;
}
.currency-wrapper {
  width: 1170px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.title {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin: 0;
  text-align: center;
  padding-bottom: 40px;
}
.subtitle {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
</style>
