<template>
  <div class="table-wrapper">
    <el-table
      :data="accountDetails"
      empty-text="List is empty"
    >
      <el-table-column
        prop="account_special_number"
        label="Account Number"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="account_type_name"
        label="Account Type"
        width="200"
        class-name="hide-for-mobile"
      >
      </el-table-column>
      <el-table-column
        prop="currency_abbreviation"
        label="Currency"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="balance"
        label="Account Balance"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="min_balance"
        label="Security Deposit"
        width="200"
        align="center"
        class-name="hide-for-mobile"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-wrapper {
    display: flex;
    justify-content: center;
    padding: 40px 70px;
    background: #E7E7D9;
    margin-top: 40px;
    width: 60%;

    @media screen and (max-width: 955px) {
      width: 98%;
      padding: 0;
    }
  }

.el-table__empty-block {
    min-height: 60px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E7E7D9;
}

.el-table {
  background: #E7E7D9 !important;
}
</style>
