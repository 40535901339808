var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-wrapper"},[_vm._m(0),_c('div',{staticClass:"header"},[_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'details' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/details')}}},[_vm._v("Account Details")]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'transactions' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/transactions')}}},[_vm._v("Statement")]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'internationalTransfer' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/international-transfer')}}},[_vm._v("SWIFT/SEPA Transfer")]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'intraTransfer' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/intra-transfer')}}},[_vm._v("Internal Transfer")])]),_c('router-view')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-container margin-bottom-120"},[_c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"account__block"},[_c('div',{staticClass:"account__text d-flex-rn flex-center"},[_c('h1',[_vm._v("FAST. INNOVATIVE. WELL-ROUNDED.")])])])])])}]

export { render, staticRenderFns }