<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <el-backtop :bottom="128">
      <div class="back-top-button">
        <i class="el-icon-arrow-up" />
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "Inter-Black";
  src: url("./assets/fonts/Inter-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraLight";
  src: url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Light";
  src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/fonts/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Thin";
  src: url("./assets/fonts/Inter-Thin.ttf") format("truetype");
}
#app {
  font-family: Inter-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
}
ul, li, p, div {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}

</style>
