<template>
  <div>
    <div class="login">
      <div class="header-wrapper">
        <div class="header__login">
          <div class="login__content">
            <div class="login__block">
              <div v-if="!isPinMode" class="form" @keyup.enter="signIn">
                <span class="header__title">Online Banking Login</span>
                <h1>LOGIN</h1>
                <el-input
                  placeholder
                  class="input"
                  type="login"
                  v-model="login"
                ></el-input>
                <h1>PASSWORD</h1>
                <el-input
                  placeholder
                  class="input"
                  type="password"
                  v-model="password"
                ></el-input>
                <div class="checkbox-wrapper"></div>
                <el-button
                  type="primary"
                  @click="signIn"
                  :loading="loadingSubmitButton"
                  >Log in</el-button
                >
              </div>
              <div v-else class="form" @keyup.enter="confirmPin">
                <span class="error-message">asd</span>
                <h1>PIN CODE</h1>
                <el-input placeholder class="input" v-model="pin"></el-input>
                <div class="checkbox-wrapper"></div>
                <el-button
                  type="primary"
                  @click="confirmPin"
                  :loading="loadingConfirmButton"
                >
                  CONFIRM
                </el-button>
              </div>
            </div>
            <div class="stripes__block">
              <div class="header__title">
                ALEXANDRIA BANCORP LIMITED COMOROS
              </div>
              <div>
                <img src="../../assets/onlineBanking/circle.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      login: "",
      password: "",
      isPinMode: false,
      pin: null,
      errorMessage: null,
      loadingSubmitButton: false,
      loadingConfirmButton: false,
    };
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit("setLoadingPage", true);
    setTimeout(() => {
      this.$store.commit("setLoadingPage", false);
    }, 1000);
    next();
  },

  computed: {
    ...mapGetters(["lang"]),
  },

  methods: {
    async signIn() {
      if (this.loadingSubmitButton === true) return;

      if (!this.login.trim() || !this.password.trim()) {
        this.errorMessage = "Invalid login or password";
        return;
      }

      this.loadingSubmitButton = true;
      this.$store
        .dispatch("signIn", { username: this.login, password: this.password })
        .then((response) => {
          if (response.code) throw response.message;
          this.isPinMode = true;
          sessionStorage.setItem("token", response.token);
          this.$store.commit("setUserName", this.login);
          this.$store.dispatch("requestPinCode", response.token);
          this.loadingSubmitButton = false;
        })
        .catch((error) => {
          this.errorMessage = error;
          this.loadingSubmitButton = false;
        });
    },

    confirmPin() {
      if (this.loadingConfirmButton) return;

      this.loadingConfirmButton = true;
      if (!this.pin) {
        this.errorMessage = "Invalid pin";
        this.loadingConfirmButton = false;
        return;
      }
      this.$store
        .dispatch("sendPin", this.pin)
        .then((response) => {
          if (response === "PIN IS NOT VALID") {
            this.errorMessage = "PIN IS NOT VALID";
          } else {
            this.$store.commit("setUser", response);
            this.errorMessage = false;
            this.isPinMode = false;
            this.$router.push("/personal-account/details");
          }
        })
        .catch((error) => {
          console.error(error);
          this.errorMessage = "Invalid pin";
        })
        .finally(() => {
          this.loadingConfirmButton = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  margin-bottom: -120px;
  padding-bottom: 150px;
  @media screen and (max-width: 1616px) {
    padding-bottom: 60px;
  }
  @media screen and (max-width: 1200px) {
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media screen and (max-width: 750px) {
    padding-bottom: 130px;
  }
}
.login__content {
  display: flex;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}
.header-wrapper {
  width: 100%;
  max-width: 1170px;
  height: auto;
  margin: 0 auto;
  padding-top: 120px;
}
.stripes__block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 675px;
  background: url("../../assets/onlineBanking/stripesBG.jpg");

  div:first-child {
    align-self: flex-start;
    height: 50%;
    width: 50%;
    padding-top: 79px;
    padding-left: 39px;
  }
  div:last-child {
    height: 100%;
  }
}
.header__title {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
}
.header__login {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  position: relative;
}
.header__login-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.login__icons {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  list-style-type: none;
  padding-right: 50px;
  @media screen and (max-width: 1384px) {
    font-size: 12px;
  }
  @media screen and (max-width: 550px) {
    padding-right: 10px;
    width: 300px;
  }
  img {
    @media screen and (max-width: 750px) {
      width: 20%;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 675px;
  padding: 70px 76px;
  box-sizing: border-box;
  @media screen and (max-width: 1384px) {
    width: 450px;
  }
  h1 {
    font-size: 24px;
  }
  .input {
    width: 395px;
    height: 40px;
    @media screen and (max-width: 1384px) {
      width: 290px;
    }
  }

  .checkbox-wrapper {
    display: flex;
    margin-bottom: 50px;
  }
}

.error-message {
  color: #cc3f55;
}
.el-button--primary {
    background: #2E4D4F;
    width: 100%;
}
</style>
