<template>
  <div class="about">
    <div class="about-wrapper">
      <div class="about-content">
        <div class="about-block padding-right-100">
          <h1 class="title">Who we are?</h1>
          <p class="text">
            Alexandria Bancorp Limited was established in 1990 in Grand Cayman,
            Cayman Islands. Alexandria Bancorp is licensed as a Class “B” Bank
            and Trust Company allowing us to provide banking and trustee
            services to international clients.
          </p>
          <p class="text">
            <b>In Cayman</b>
            , Alexandria holds a restricted Mutual Fund Administrator Licence
            and a Securities Investment Business Licence through Alexandria
            Global Investment Management Ltd. These licenses enable us to
            provide investment management services as well as securities trading
            and related services. Alexandria Bancorp Limited and Alexandria
            Global Investment Management Ltd. are both licensed and regulated by
            the Cayman Islands Monetary Authority.
          </p>
          <p class="text">
            <b>In The Comoros Islands</b>
            , Alexandria holds a
            <b>Non-FATCA/CRS compliant</b>
            private Trust branch lexandria Bancorp Limited Comoros licensed by
            the Mwali International Services Authorities which is not regulated
            by the Central Bank of Comoros and gives way to such banking
            advantages as: TAX-Free banking solutions, more comprehensible
            account opening procedures, recognition of High-risk and other
            monitored jurisdictions. This Trust branch gave us an opportunity to
            reach out for a larger audience and share our profound banking
            experience with them.
          </p>
          <div class="btn-block">
            <el-button @click="$router.push('/Contacts')" id="News"
              >Get started</el-button
            >
          </div>
        </div>
        <div class="about-block">
          <div class="about-img-block">
            <img src="../../assets/home/imgAbout.png" alt="" />
          </div>
          <p class="text">
            <b>In Barbados</b>
            , Alexandria Trust Corporation is licensed and regulated by the
            Central Bank of Barbados. Alexandria Trust provides specialized
            trustee and corporate administration services to private clients and
            companies. Barbados, with its extensive network of tax treaties and
            favourable legislation, provides an attractive jurisdiction for
            international planning. Some of the more commonly relied upon tax
            treaties include those with China, Latin America or Canada.
          </p>
          <p class="text">
            Alexandria Bancorp and Alexandria Trust are wholly-owned by Guardian
            Capital Group Limited, based in Toronto, Canada. Guardian is a well
            capitalized diversified financial services company established in
            1962. Guardian has substantial depth and expertise in the investment
            management sector, particularly with institutional and high net
            worth clients. Guardian shares are publicly traded on the Toronto
            Stock Exchange.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.about-wrapper {
  width: 1170px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.about-content {
  display: flex;
}
.about-block {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.padding-right-100 {
  padding-right: 100px;
}
.about-img-block {
  text-align: right;

  img {
    padding-bottom: 100px;
  }
}
.title {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #000000;
  margin: 0;
  padding-bottom: 40px;
}
.text {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
  padding-bottom: 24px;
}
.btn-block {
  margin-top: 40px;
}
.el-button {
  width: 250px;
  height: 62px;
  background: #2e4d4f;
  color: #fff;
}
</style>
