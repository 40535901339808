<template>
  <header>
    <div class="nav">
      <div class="nav__wraper wraper">
        <nav class="nav__navigation">
          <ul v-if="!isPersonalAccountPage" class="nav__link-block">
            <li class="">
              <div class="hamburger-menu">
                <input id="menu__toggle" ref="menuToggle" type="checkbox" />
                <label class="menu__btn" for="menu__toggle">
                  <span></span>
                </label>
                <div class="menu__box">
                  <div>
                    <ul>
                      <li>
                        <span
                          @click="gotoRoute('/CorporateBanking')"
                          class="menu__item color-green"
                          >services</span
                        >
                      </li>
                      <li>
                        <span
                          @click="gotoRoute('/CorporateBanking#fees')"
                          class="menu__item"
                          >sad</span
                        >
                      </li>
                      <li>
                        <span
                          @click="gotoRoute('/apply-online')"
                          class="menu__item"
                          >asd</span
                        >
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <span
                          @click="gotoRoute('/PersonalBanking')"
                          class="menu__item"
                          >asd</span
                        >
                      </li>
                      <li>
                        <span
                          @click="gotoRoute('/PersonalBanking#fees')"
                          class="menu__item"
                          >asd</span
                        >
                      </li>
                      <li>
                        <span
                          @click="gotoRoute('/apply-online')"
                          class="menu__item"
                          >asd</span
                        >
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <span @click="gotoRoute('/About')" class="menu__item">
                          asd
                        </span>
                      </li>
                      <li>
                        <span
                          @click="gotoRoute('/Cryptocurrency')"
                          class="menu__item"
                        ></span>
                      </li>
                      <li>
                        <span @click="gotoRoute('/Contact')" class="menu__item"
                          >asd</span
                        >
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <span
                          @click="gotoRoute('/Businesscard')"
                          class="menu__item"
                          >sad</span
                        >
                      </li>
                      <li>
                        <span
                          @click="gotoRoute('/BecomeOurAgent')"
                          class="menu__item"
                          >asd</span
                        >
                      </li>
                      <li>
                        <span @click="gotoRoute('/OnlineBanking')" class="menu__item"
                          >Online bank
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav__logo">
              <img src="../assets/logo.png" alt="" />
            </li>
            <li class="link-block__item">
              <span @click="gotoRoute('/CorporateBanking')">Services</span>
            </li>
            <li class="link-block__item">
              <span @click="gotoRoute('/PersonalBanking')">Team</span>
            </li>
            <li class="link-block__item">
              <span @click="gotoRoute('/PersonalBanking')">About us</span>
            </li>
            <li class="link-block__item">
              <span @click="gotoRoute('/PersonalBanking')">Careers</span>
            </li>
            <li class="link-block__item">
              <span @click="gotoRoute('/PersonalBanking')">Contact us</span>
            </li>
          </ul>

          <ul v-if="!isPersonalAccountPage" class="nav__link-block">
            <li class="sbonline-button link-block__item">
              <span class="sbonline" @click="gotoRoute('/OnlineBanking')"
                >Online bank</span
              >
            </li>
            <li class="link-block__item tel__block">
              <span @click="gotoRoute('/Contact')">+44 7507 900 176</span>
            </li>
            <!-- <li class="link-block__item">
              <el-row class="block-col-2">
                <el-dropdown @command="selectLang">
                  <span class="el-dropdown-link">
                    {{ language }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="EN">EN</el-dropdown-item>
                    <el-dropdown-item command="TR">TR</el-dropdown-item>
                    <el-dropdown-item command="RU">RU</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-row>
            </li> -->
          </ul>
          <div v-else class="nav__admin-block">
            <ul class="nav__link-block">
              <li class="link-block__item">
                <span @click="logout()">
                  <!-- <img src="../assets/header/log-out.png" alt=""> -->
                  Out
                </span>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      language: this.$store.state.language,
      scrollPosition: 0,
    };
  },

  mounted() {
    document.addEventListener("scroll", this.setScrollPosition);
  },

  methods: {
    setScrollPosition() {
      this.scrollPosition = window.scrollY;
    },

    selectLang(lang) {
      this.language = lang;
      this.$store.commit("setLang", lang);
    },

    gotoRoute(link) {
      this.$refs.menuToggle.checked = false;
      if (link === "/Login" && this.isAuth) {
        this.$router.push("/personal-account/details");
      } else {
        this.$router.push(link);
      }
    },

    logout() {
      this.$router.push("/login");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    isAuth() {
      return !!this.$store.getters.user;
    },
    isPersonalAccountPage() {
      return [
        "details",
        "transactions",
        "internationalTransfer",
        "intraTransfer",
      ].includes(this.$route.name);
    },
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.setScrollPosition);
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
}
.nav__navigation {
  width: 100%;
  min-height: 110px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: auto;
  background: #ebe7db;
  padding-bottom: 20px;
  @media screen and (max-width: 750px) {
    justify-content: space-around;
  }
}
.nav__logo {
  position: absolute;
  top: 0;
  cursor: pointer;
}

.nav__logo-block {
  width: auto;
  height: 100%;
  position: absolute;
  top: 85px;
  cursor: pointer;

  @media screen and (max-width: 750px) {
    top: 80px;
    right: auto;
  }
}

.logo-img {
  width: auto;
  height: auto;
  margin-right: 13px;
}
.active-nav {
  color: #ff7f36;
}
.logo-text {
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 820px) {
    font-size: 22px;
  }
  &__hiden {
    top: -12px;
    right: -129px;
  }
}
.logo-text:after {
  content: "";
  width: 60px;
  height: 60px;
  //   background: url(../assets/header/logo.png);
  position: absolute;
  top: -73px;
  right: 93px;
  cursor: pointer;
}
.nav__link-block {
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  height: 110px;
  display: flex;
  align-items: flex-end;
  &:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
@media screen and (max-width: 750px) {
  .nav__link-block:first-child {
    margin-right: auto;
  }
}

.link-block__item {
  margin-right: 45px;
  transition-duration: 0.1s;
  cursor: pointer;
  @media screen and (max-width: 520px) {
    margin-right: 5px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}
.nav-mobile {
  display: none;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.el-dropdown-link {
  color: #000000;
}

/* мобильное меню */
#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked ~ .menu__btn {
  top: 0;
  left: 0;
}
#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
  background: #ffffff;
  width: 20px;
  right: 15px;
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
  background: #ffffff;
  width: 20px;
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
  background: #ffffff;
  width: 20px;
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}
#menu__toggle:checked ~ label {
  background: #2e4d4f;
  width: 400px;
  height: 50px;
  padding: 0px 17px;
  @media screen and (max-width: 562px) {
    width: 200px;
  }
}
.menu__btn {
  display: flex;
  align-items: center;
  position: fixed;
  top: 9px;
  width: 29px;
  height: 26px;
  cursor: pointer;
  z-index: 20;
}
.hamburger-menu {
  display: none;
}
.nav-mobile__block {
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  margin-right: 20px;
  margin-left: 20px;
}
.nav-mobile__text {
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #b1b1b1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu__btn > span {
  display: block;
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: #000000;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #000000;
  transition-duration: 0.25s;
}
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 16px;
  height: 2px;
  background-color: #000000;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}

.menu__box {
  display: flex;
  justify-content: center;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #ebe7db;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
  transition-duration: 0.25s;
  z-index: 10;
  @media screen and (max-width: 562px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  ul {
    list-style-type: none;
    text-align: start;
    @media screen and (max-width: 820px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.menu__item {
  display: block;
  padding: 12px 24px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #4d4d4d;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 820px) {
    font-size: 15px;
    padding-right: 0;
    padding-bottom: 0;
  }
}
.menu__item:hover {
  background-color: #cfd8dc;
}
.color-green {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #2e4d4f;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 820px) {
    font-size: 15px;
    padding-right: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 750px) {
  .display-block-none {
    display: none;
  }
}

.sbonline {
  color: #fff;
}
.tel__block {
    font-family: Inter-Bold;
}
.sbonline-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 48px;
  background: #2e4d4f;
}

.nav__admin-block {
  width: 80%;
  justify-content: flex-end;
  display: flex;
}
</style>
