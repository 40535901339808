<template>
  <div class="transaction-wrapper">
    <div class="content">
      <div class="filter-wrapper">
        <el-select
          class="filter-wrapper__select"
          v-model="selectedAccount"
          filterable
          placeholder="Filter by account"
          value-key="currency"
        >
          <el-option
            v-for="item in accountTransactions"
            :key="item.currency"
            :label="item.currency"
            :value="item"
          >
          </el-option>
        </el-select>
        <div @click="printTable">
          <PrintIcon />
        </div>
      </div>
      <el-table
        :data="currentTransactions"
        style="width: 100%"
        empty-text="List empty"
      >
        <el-table-column
          prop="date"
          label="Date"
          width="100"
          :formatter="getDate"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="transfer_type_name"
          label="Transfer Type"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="account_special_number"
          label="Sender"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="beneficiary_address"
          label="Beneficiary"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="transfer_number"
          label="Transaction Number"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="min_balance" label="Amount" align="center">
        </el-table-column>
        <el-table-column prop="status_name" label="Status" width="100" sortable>
        </el-table-column>
        <el-table-column :formatter="getBalance" label="Balance" width="150">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// import PrintIcon from '../../assets/svgIcons/print'

export default {
  data() {
    return {
      selectedAccount: null,
    };
  },

  mounted() {
    this.$store.dispatch("getAccountTransaction");
  },

  methods: {
    // getDate(row, column, cellValue) {
    //   return moment(cellValue)
    //     .format('YYYY-MM-DD')
    // },
    getBalance(row) {
      return `${row.balance} (${row.currency_abbreviation})`;
    },
    printTable() {
      window.print();
    },
  },

  computed: {
    accountTransactions() {
      return this.$store.getters.accountTransactions;
    },

    currentTransactions() {
      if (this.selectedAccount) return this.selectedAccount.transaction;
      return [];
    },
  },

  components: {
    // PrintIcon,
  },
};
</script>

<style lang="scss" scoped>
.transaction-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
  width: 100%;
}

.filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #FCB004;
    border-radius: 60px;
    z-index: 1111;
    padding: 6px;

  &__select {
    margin-right: 25px;
  }

  @media print {
    display: none;
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  padding: 50px;
  border-radius: 60px;
}
</style>
