<template>
  <div class="intra-transfer-wrapper margin-bottom-120">
    <h2>Convert Currency and/or Initiate an Internal Transfer</h2>

    <div class="form-wrapper">
      <div class="fields">
        <el-select
          class="fields__select"
          v-model="firstSelected"
          filterable placeholder="From account"
          no-data-text="List is empty"
        >
          <el-option
            v-for="item in accountDetails"
            :key="item.id"
            :label="`${item.account_special_number} (${item.currency_abbreviation}) (${item.balance})`"
            :value="item.account_special_number"
          >
          </el-option>
        </el-select>

        <el-select
          class="fields__select"
          v-model="secondSelected"
          filterable placeholder="To account"
          :disabled="!firstSelected"
        >
          <el-option
            v-for="item in accountDetails"
            :key="item.id"
            :disabled="item.account_special_number === firstSelected"
            :label="`${item.account_special_number} (${item.currency_abbreviation}) (${item.balance})`"
            :value="item.account_special_number"
          >
          </el-option>
        </el-select>
      </div>

      <div class="fields">
        <el-input class="fields__input" placeholder="Amount" v-model="amount" type="number" />
        <el-input class="fields__input" placeholder="Payment Reference" v-model="reference" />
      </div>
      <p v-if="showErrorMessage" class="errorMessage">All fields must be filled</p>

      <div class="button-wrapper">
        <el-button
          @click="sendTransfer"
          :loading="loadingSubmitButton"
          type="primary"
        >
         sad
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  data() {
    return {
      firstSelected: null,
      secondSelected: null,
      amount: null,
      reference: null,
      checked: false,
      loadingSubmitButton: false,
      showErrorMessage: false,
    }
  },

  methods: {
    async sendTransfer() {
      const {
        firstSelected, secondSelected, amount, reference,
      } = this

      if (!firstSelected || !secondSelected || !amount || !reference) {
        this.showErrorMessage = true
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return
      }
      this.loadingSubmitButton = true

      await this.$store.dispatch(
        'sendIntraTransfer',
        {
          amount, from_account: firstSelected, to_account: secondSelected, reference,
        },
      ).then(data => {
        if (data.code === 500 || data.message) {
            this.$message({
              message: data.message,
              type: "warning",
            });
        } else {
          this.firstSelected = null
          this.secondSelected = null
          this.amount = null
          this.reference = null
          this.checked = false

          this.$message({
            message: "Payment order was created",
            type: "success",
          });
        }
        this.loadingSubmitButton = false
      })
    },
  },

  computed: {
    ...mapGetters(['lang']),
    accountDetails() {
      return this.$store.getters.accountDetails
    },
  },
}
</script>

<style lang="scss" scoped>
  .intra-transfer-wrapper {
    height: 60vh;

    @media screen and (max-width: 955px) {
      height: auto;
    }
  }
.intra-transfer-wrapper {
  h2 {
    margin-top: 90px;
  }
}
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 25px 25px;
    background: #E7E7D9;

    @media screen and (max-width: 955px) {
      align-items: center;
      padding: 20px 10px;
    }
  }

  .fields {
    display: flex;
    margin-bottom: 25px;

    &__select,
    &__input {
      width: 300px;
      margin-right: 25px;
      margin-left: 25px;
      margin-top: 25px;

      @media screen and (max-width: 955px) {
        width: 260px;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 955px) {
      flex-direction: column;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
</style>
